body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --accent: var(--ash-black);

  }


.progress {
    color: var(--accent);
    position: fixed;
    bottom: 0;
    width: 80px;
    height: 80px;
    margin: 0;
    padding: 0;
  }
.roun{
    
    position: fixed;
    bottom: 0;
    right: 0;

}
  
  .progress svg {
    transform: translateX(-100px) rotate(-90deg);
  }
  
  circle {
    stroke-dashoffset: 0;
    stroke-width: 10%;
    fill: none;
  }
  
  .progress .indicator {
    stroke: var(--accent);
  }