

section {
  margin-left: 100px;
  position: relative;
}

.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 5em;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
  margin-right: 30px;
}

@media screen and (max-width: 800px) {

  .parallax .scroller {
    font-size: 4em;
  
  }
}
@media screen and (max-width: 445px) {

  section{
    margin-left: 60px;
  }
  .parallax .scroller {
    font-size: 2.5em;
  
  }
}

@media screen and (max-width: 440px){
  section{
    margin-left: 30px;
  }
}

@media screen and (max-width: 345px) {
  section{
    margin-left: 50px;
  }
}
