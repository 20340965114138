.navbar-con{
    z-index: 100;
    position: fixed;
    width: 100px;
    height: 100vh;
    border-right: 2px solid black;


}

.navbar-links{
    list-style: none;
    text-decoration: none;
    padding-bottom: 100px;
}
.point{
    text-decoration: none;
    transition: background-color 0.5s ease;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    text-decoration: none;
    color: black;
    font-family: 'poppins', sans-serif;
    font-weight: bold;
    width: 100%;


}
li{
    padding: 10px;
    text-decoration: none;
    color: black;
    font-family: 'poppins', sans-serif;
    font-weight: bold;
    width: 100%;

}


ul{
    padding: 0;
    margin: 0;
}
.navbar{
    height: 100vh;
}
.navbar-links{
    width: 100px;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
    height: 250px;
    bottom: 0;
}
.point:hover{
    background-color: rgb(141, 137, 132);
    transition: background-color 0.3s ease;
    cursor: pointer;
}

@media screen and (max-width: 445px){
    .navbar-logo{
        transform: scale(0.7);
    }
    .navbar-con{
        width: 60px;
    }
    .point{
        font-size: 0.9em;
    }
    .navbar-links{
        display: none;
    }
    .navbar{
        height: 100%;
    }
}

@media screen and (max-width: 440px){
    .navbar-con{
        width: 30px;
    }
}


  
    