.project-con {
    font-family: "poppins", sans-serif;
}

.name1 {
    font-size: 3em;
    text-align: center;
}

.projects {
    margin-left: 150px;
    margin-right: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 1100px) {
    .projects {
        grid-template-columns: 1fr 1fr;
    }
    
}
@media screen and (max-width: 740px) {
    .projects {
        grid-template-columns: 1fr;
    }
}


.project-1 , .project-2, .project-3 {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-top: 30px;
    margin: 30px;
    border-radius: 26px;
    box-shadow: 5px 0px 20px rgba(0, 0,0 , 0.3); /* Add the drop shadow */

}
.project-1{
    background-color: #383335;
    color: #ffffff;

}

.project-1 .project-name{
    
    width: 100%;
    text-align: center;
    font-size: 2em;
    padding-bottom: 20px;
    
}
.project-2{
    background-color: #ffffff;
    font-weight: 500;
}
.project-2  .project-name{
    
    width: 100%;
    text-align: center;
    font-size: 2em;
    padding-bottom: 20px;
    
}


.project-3{
    background-color: #B8B5AC;
    font-weight: 500;
}
.project-3  .project-name{
    
    width: 100%;
    text-align: center;
    font-size: 2em;
    padding-bottom: 20px;
    
}

@media screen and (max-width: 950px) {
    .projects{
        margin-left: 100px;
    }
    .project-desc{
        font-size: 15px ;
    }
    .project-name{
        font-size: 15px;
    }

}
@media screen and (max-width: 740px) {
    .projects {
        margin-right: 20px;
    }
}
@media screen and (max-width: 440px) {
    .projects {
        margin-left: 30px;
    }
}

@media screen and (max-width: 400px) {
    .projects {
        margin-right: 30px;
    }
    .project-1 , .project-2, .project-3 {
        margin: 10px;
        padding-left: 25px;
        padding-right: 25px;
    }



}

@media screen and (max-width: 380px) {
    .project-desc{
        font-size: 13px;
    }
    .project-name{
        font-size: 15px;
    }
}






/* ---------------------- */

/* CSS */
.button-27 {
  appearance: none;
  background-color: #000000;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  text-align: center;
  padding: 15px;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}
.project-desc{
    margin-bottom: 23px;
}

.button-27:disabled {
  pointer-events: none;
}
.button-27 img{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.button-27:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-27:active {
  box-shadow: none;
  transform: translateY(0);
}

.but-con{
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

/* -------------- */

.contact-me-con{
    margin-left: 50px;
    margin-right: 50pxs;
    margin-top: 100px;
}

.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

/* From Uiverse.io by shadowmurphy */ 
.input {
    margin-top: 30px;
    border: 2px solid transparent;
    width: 60%;
    height: 3em;
    padding-left: 0.8em;
    outline: none;
    overflow: hidden;
    background-color: #F3F3F3;
    border-radius: 10px;
    transition: all 0.5s;
  }

  @media screen and (max-width: 420px) {
    .input{
        width: 85%;
    }
    
  }
  
@media screen and (max-width: 440px) {
    .contact-me-con{
        margin-left: 30px;
        margin-right: 15px;
    }
    .input{
        width: 89%;
    }
    
}

  
  
  .input:hover,
  .input:focus {
    border: 2px solid #4A9DEC;
    box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
    background-color: white;
  }
  /* From Uiverse.io by adamgiebl */ 
button {
    position: relative;
    display: inline-block;
    margin: 15px;
    padding: 15px 30px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    color: #383335;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #383335;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #383335;
  }
  
  button:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 #383335;
  }
  
  button:active {
    transform: scale(0.9);
  }
  