.footer-con{
    height: 180px;
    background-color: black;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.social-con{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.social{
    display: flex;
    color: white;
}
.log{
    display: flex;

}
.logs{
    width: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

.log:hover{
    cursor: pointer;
}

.footer{
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 50px;
}